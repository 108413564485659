.background {
  background-color: rgb(10, 11, 26);
  z-index: 1;
  position: relative;
  height: 45vw;
  padding-left: 9vw;
  top: -100px !important;
  margin-bottom: 100px;
  background-image: url("../../public/assets/caricari.jpg");
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
  @media only screen and (max-width: 1250px) {
    padding-left: 10vw;
    height: 410px;
    margin-bottom: 110px;
  }
  @media only screen and (max-width: 768px) {
    height: 350px;
    padding-left: 6vw;
    margin-bottom: 80px;
  }
}

* {
  margin: 0;
}

.title {
  color: white;
  width: 100vw;
  display: block;
  padding-top: 15vw;
  font-family: "Libre Baskerville", serif;
  font-size: 80px;
  font-weight: 700;
  line-height: 96px;
  letter-spacing: 0;
  text-align: left;
  position: relative;
  z-index: 3;
  @media only screen and (max-width: 768px) {
    padding-top: 30vw;
    font-size: 200%;
  }
  @media only screen and (min-width: 768px) and (max-width: 1250px) {
    padding-top: 12vw;
    font-size: 300%;
  }
}

.banner-des {
  font-family: "Nunito", serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0;
  text-align: left;
  color: white;
  margin-top: 1vw;
  @media only screen and (max-width: 1250px) {
    //width: 60vw;
    //margin-bottom: -5vw;
    //font-size: 80%;
  }
  @media only screen and (max-width: 768px) {
    line-height: 24px;
    margin-top: -5vw;
    font-size: 14px;
  }
}

.gradient {
  background-image: linear-gradient(rgba(255, 0, 0, 0), rgb(10, 11, 26));
  height: 300px;
  width: 100vw;
  overflow-x: hidden;
  position: relative;
  top: -55vh;
  z-index: 1;
  left: -9vw;
  @media only screen and (max-width: 768px) {
    height: 140px;
    top: -246px;
    left: -6vw;
  }
  @media only screen and (max-width: 1250px) and (min-width: 768px) {
    height: 100px;
    top: -240px;
    left: -10vw;
  }
  @media only screen and (max-width: 1250px) and (min-width: 900px) {
    height: 100px;
    top: -250px;
    left: -10vw;
  }
}

.circular-bars{
  display: grid;
  grid-auto-flow: column;
  margin-top: 200px;
  position: relative;
  z-index: 9;
  padding-right: 9vw;
  @media only screen and (max-width: 768px) {
    margin-top: 80px;
    --spacer: 1.2rem;
    gap: var(--spacer);
    overflow-x: auto;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none;
    overscroll-behavior: contain;
    align-content: center;
  }
  @media only screen and (max-width: 1250px) and (min-width: 768px) {
    margin-top: 80px;
    --spacer: 1.2rem;
    gap: var(--spacer);
    overflow-x: auto;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none;
    overscroll-behavior: contain;
    align-content: center;
  }
}

.circular-bar-main-div{
  height: 208px;
  width: 208px;
  @media only screen and (max-width: 768px) {
    height: 144px;
    width: 144px;
  }
}
.circular-bar-text{
  font-family: Nunito, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.label-icon{
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  &:hover{
    transform: scale(1.05);
  }
}