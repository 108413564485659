.review-box {
  box-sizing: border-box;
  position: relative;
  right: 2.17%;
  background: #0A0B1A;
  border: 2px solid #E5AF56;
  border-radius: 16px;
  height: 244.708740234375px;
  width: 368px;
  left: -10px;
  top: 8px;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  @media only screen and (max-width: 768px) {
    height: 240px;
    width: 360px;
  }
  &:hover{
    transform: translateY(-8px) translateX(8px);
  }
}

.review-upper-box {
  height: 244.708740234375px;
  width: 368px;
  left: 10px;
  border-radius: 16px;
  background: linear-gradient(143.63deg, #276C73 5.43%, #5293E8 110.6%);
  margin-left: 10px;
  margin-bottom: 8px;
  @media only screen and (max-width: 768px) {
    height: 240px;
    width: 360px;
  }
}

.review-main-div {
  margin-top: 40px;
  display: grid;
  grid-auto-flow: column;
  --spacer: 1.2rem;
  gap: var(--spacer);
  overflow-x: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;
  overscroll-behavior: contain;
  align-content: center;
}

.review-main-div::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.review-box-user {
  display: flex;
  margin-left: 24px;
  margin-top: 36px;
}

.review-box-user-location-name {
  position: relative;
  margin-left: 14px;
  float: right;
}

.review-box-user-name {
  font-family: Quattrocento, serif;
  text-align: left;
  color: white;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
}

.review-box-user-location {
  font-family: Nunito, serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: #E5C558;
  padding-top: 10px;
}

.review-text {
  margin-left: 24px;
  margin-top: 24px;
  padding-right: 10px;
  font-family: Nunito, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  opacity: 0.9;
}

.arrows{
  padding-right: 10vw;
  padding-top: 10px;
  font-family: Nunito, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  text-align: right;
  position: absolute;
  right: 0;
  color: rgba(255, 255, 255, 0.5);
  float: right;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    padding-top: 5px;
    padding-right: 6vw;
    font-size: 14px;
  }
}