.footer {
    width: 100%;
    background-color: rgb(10, 11, 26);
    padding: 20px 0;
    text-align: center;
    color: white;
    font-size: 16px;
}

.footer-line {
    background-color: rgba(0, 0, 255, 0.7);
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 20px;
    font-family: "Nunito", serif;
}

.content-box {
    margin-top: 2vh;
    margin-bottom: 50px;
    padding-left: 4.5vw;
}